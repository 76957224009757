import * as React from 'react'
import Template from "../../components/template";
import {Link} from "gatsby";
import {projectsData} from "../../components/projects_data";
import Project from "../../components/project";

const Page = ({lang}) => {
    const p = projectsData.find(item => item.name === "BestPersons.ru")
    return (
        <Template title="BestPersons.ru">
            <div className="block text-sm breadcrumbs">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/projects/">Projects</Link>
                    </li>
                    <li><h2 className="font-bold">BestPersons.ru</h2></li>
                </ul>
            </div>
            <div className="block space-y-2">
                <Project p={p} full={true}/>
            </div>
        </Template>
    )
}

export default Page