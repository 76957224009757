import React from "react"
import RoleIcon from "./icons/role";
import {Link} from "gatsby";
import "./project.css"
import "./tags.css"

export default function Project({p, full}) {
    let company
    if (p.companyLink) {
        company = <a rel="nofollow" target="_blank" href={p.companyLink}>{p.companyName}</a>
    } else if (p.companyName) {
        company = <React.Fragment>{p.companyName}</React.Fragment>
    }
    if (company) company = <React.Fragment> @ {company}</React.Fragment>
    let stack
    if (p.stack) {
        const tags = p.stack.map(tag => <span key={tag}>{tag}</span>)
        stack = <div className="tags">
            {tags}
        </div>
    }

    let years = ""
    if (p.years) {
        years = <React.Fragment>{p.years[0]} &mdash; {p.years[1] || "Present"}</React.Fragment>
    }
    let role = "";
    if (p.role) {
        role = <div className="text-sm inline-flex items-center text-gray-500" title="Role"><RoleIcon className="inline w-4 mr-1 fill-current"/>{p.role}</div>
    }
    let more = "";
    if (p.more && !full) {
        more = <Link className="btn btn-xs btn-primary" to={p.more}>Read more</Link>
    }
    let body = "";
    if (full && p.fullBody)
        body = p.fullBody;
    else
        body = p.body;

    return <div className="space-y-2">
        <div className="flex items-baseline">
            <div className="text-xl flex-grow">
                <span className="font-bold">{p.name}</span>{company}
            </div>
            <div className="text-gray-400 text-xs sm:text-sm whitespace-nowrap ml-1">{years}</div>
        </div>
        {role}
        {body}
        {more}
        {stack}
    </div>
}
