import * as React from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import Photos from "./photos";

export const projectsData = [
    {
        name: "Software Architecture",
        companyName: "Coin Metrics",
        companyLink: "https://coinmetrics.io",
        body: <div>
            <p>What I've done as a VP of Engineering:</p>
            <ul className="list ml-2">
                <li>Created "Chain Monitor" Coin Metrics product (FARUM).</li>
                <li>Establishing various company-wide engineering standards, reliability guidelines, API design specifications, common libraries, and services to maintain consistency between different projects and teams.</li>
                <li>Software engineering and scaling strategy, making architectural decisions.</li>
                <li>Software engineering oversight and reducing the complexity and maintainability of solutions.</li>
                <li>Incident prediction, resolving, and troubleshooting.</li>
                <li>Scaling engineering org structure, interviewing candidates, and mentoring technical leaders.</li>
                <li>Got O-1, EB-2 NIW (US) &amp; Global Talent (UK) visas and ultimately moved to Boston.</li>
                <li>Developed the next-generation specialized distributed database and high-performant query engine to work with terabytes of data.</li>
            </ul>
            <br/>
            <p>What I've done as a Lead Software Engineer (till Jan 2021):</p>
            <ul className="list ml-2">
                <li>Created a highly loaded, distributed, and reliable Coin Metrics API (API v4)</li>
                <li>Created an Access Management System for API keys (AMS API)</li>
                <li>Created a Universal Blockchain Explorer API (ATLAS API)</li>
                <li>Created a Distributed rate-limiting system for API calls</li>
            </ul>
        </div>,
        stack: ["Highload", "Distributed Systems", "System design", "Kotlin", "Kafka", "Postgres", "HTML/CSS/JS"],
        years: [2019],
        role: "VP of Engineering"
    },
    {
        name: "Mind Energy",
        body: <React.Fragment>
            <p>An experimental mind-reading technology.</p>
            <p>Read more: <a target="_blank" rel="nofollow" href="https://mind.energy">mind.energy</a> &amp; <a target="_blank" rel="nofollow" href="https://docs.mind.energy">docs.mind.energy</a></p>
        </React.Fragment>,
        stack: ["Distributed Systems", "Quantum effects"],
        years: [2024],
        role: "Founder"
    },
    {
        name: "Chatovod",
        body: <React.Fragment>
            <p>One of the most popular multi-user chat room services.</p>
            <p><a target="_blank" rel="nofollow" href="https://chatovod.com">chatovod.com</a></p>
        </React.Fragment>,
        stack: ["Highload", "Java/Kotlin", "MySQL", "HTML/CSS/JS"],
        years: [2024],
        role: "Founder"
    },
    {
        name: "AppMetr",
        companyName: "Pixonic",
        companyLink: "https://pixonic.com",
        body: <p>The architecture of in-house distributed, high-load, fault-tolerant analytical system AppMetr. It handles more than 1 billion events per day, stores more than 100 Tb of data.</p>,
        fullBody: <React.Fragment>
            <p>The architecture of in-house distributed, high-load, fault-tolerant analytical system AppMetr. It handles more than 1 billion events per day, stores more than 100 Tb of data.</p>
            <p>The main features are an efficient in-house columnar database (I've created it), near real-time analytics, schemaless custom events, powerful UI for the end-users.</p>
            <p>The system provides a fast way to filter, group, analyze a huge amount of events from mobile devices and servers in seconds. It allows inspecting the lifecycle of an individual user or some user group. Besides base features, it provides advanced analytics like cohort analysis, user retention analysis, etc.</p>
            <p>We use Apache Cassandra as cold storage, Apache Kafka for queues, and in-house Java software for high-performance querying.</p>
            <p>You can get more details from this <a href="https://www.youtube.com/watch?v=F_moPIR-cNU" target="_blank" rel="nofollow">video</a> or other videos in the <Link to="/media/">media section</Link>.</p>
            <p>Managed a team of 5 Software Engineers.</p>
            <p>Was a speaker at Saint HighLoad++ 2019 and Joker 2019 conferences.</p>
        </React.Fragment>,
        more: "appmetr",
        stack: ["Highload", "Distributed Systems", "Java", "Apache Cassandra"],
        years: [2016, 2019],
        role: "Lead Software Engineer / Team Lead"
    },
    {
        name: "Chatovod",
        body: <React.Fragment>
            <p>One of the most popular multi-user chat room services.</p>
            <p><a target="_blank" rel="nofollow" href="https://chatovod.com">chatovod.com</a></p>
        </React.Fragment>,
        stack: ["Highload", "Java/Kotlin", "MySQL", "HTML/CSS/JS"],
        years: [2009, 2022],
        role: "Founder"
    },
    {
        name: "SkyPetr",
        companyName: "VS Lab",
        body: <p>
            The server part of a distributed system for collecting and analyzing publicly available information on the Internet.
        </p>,
        stack: ["Java", "HTML/CSS/JS"],
        years: [2009, 2010],
        role: "Lead Java Developer"
    },
    {
        name: "BestPersons.ru",
        body: <p>More than a social aggregator. It was launched before FriendFeed which was ultimately sold to Facebook.</p>,
        fullBody: <div className="space-y-4">
            <div className="text-center">
                <StaticImage alt="BestPersons.ru logo" src="../images/bp/logo.png" className="my-2"/>
            </div>
            <p className="text-center">More than a social aggregator.</p>
            <Photos name="bp"/>
            <div className="aspect-w-16 aspect-h-9">
                <iframe src="https://www.youtube.com/embed/cv6Y0f8EHB4"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
            <p>Unfortunately, the project is <Link to="/ru/news/2021-08-07-bp-is-closed">closed</Link>.</p>
        </div>,
        more: "bp",
        stack: ["Highload", "Distributed Systems", "Java", "HTML/CSS/JS"],
        years: [2007, 2009],
        role: "Founder"
    },
    {
        name: "City Chat / Space Doors / CD.RU",
        body: <React.Fragment>
            <p>Created a cross-platform social network City Chat / Spacedoors / CD.RU.</p>
            <p>It had chat rooms where people could communicate using any device (J2ME & Symbian mobile phones, a website). It was similar to WhatsUp and launched before WhatsUp.</p>
            <p>I was responsible for the architecture and core services of the system..</p>
            <p>Some insight can be found <a href="https://vc.ru/life/64998-kak-my-sdelali-analog-whatsapp-v-rossii-v-nulevye" target="_blank" rel="nofollow">here</a>.</p>
        </React.Fragment>,
        stack: ["Highload", "Distributed Systems", "Java", "HTML/CSS/JS", "J2ME", "Symbian"],
        years: [2005, 2008],
        role: "Lead Java Developer / Team Lead"
    },
    {
        name: "Pushkino.info",
        body: <React.Fragment>
            <p>Created:</p>
            <p>- A Pushkino city website pushkino.org / popular local social network from scratch (PHP)</p>
            <p>- Online stores (PHP)</p>
            <p>- Internet Club management software (Java)</p>
        </React.Fragment>,
        stack: ["Java", "PHP", "HTML/CSS/JS"],
        years: [2003, 2005],
        role: "Lead Software Engineer"
    },
    {
        name: "Freelance projects",
        body: <p>Development and sale of own software and websites.</p>,
        stack: ["Java", "PHP", "VB", "HTML/CSS/JS"],
        years: [2000, 2003]
    }
]